import Main from '@/components/main/main'
// import parentView from '@/components/parent-view'

/**
 * iview-admin中meta除了原生参数外可配置的参数:
 * meta: {
 *  title: { String|Number|Function }
 *         显示在侧边栏、面包屑和标签栏的文字
 *         使用'{{ 多语言字段 }}'形式结合多语言使用，例子看多语言的路由配置;
 *         可以传入一个回调函数，参数是当前路由对象，例子看动态路由和带参路由
 *  hideInBread: (false) 设为true后此级路由将不会出现在面包屑中，示例看QQ群路由配置
 *  hideInMenu: (false) 设为true后在左侧菜单不会显示该页面选项
 *  access: (null) 可访问该页面的权限数组，当前路由设置的权限会影响子路由
 *  icon: (-) 该页面在左侧菜单、面包屑和标签导航处显示的图标，如果是自定义图标，需要在图标名称前加下划线'_'
 *  beforeCloseName: (-) 设置该字段，则在关闭当前tab页时会去'@/router/before-close.js'里寻找该字段名对应的方法，作为关闭前的钩子函数
 *  keepAlive: 是否需要缓存（true)需要缓存 不需要缓存可以不设置
 * }
 */
// window.routers = {}
const router = [
  {
    path: '/login',
    name: 'login',
    meta: {
      title: 'Login - 登录',
      hideInMenu: true
    },
    component: resolve => (require(['@/views/login/login.vue'], resolve))
  },
  {
    path: '/login-new-tenant',
    name: 'login-new-tenant',
    meta: {
      title: '登录中...',
      hideInMenu: true
    },
    component: () => import('@/views/login-new-tenant/index.vue')
  },
  {
    path: '/loading-login-sso',
    name: 'loading-login-sso',
    meta: {
      title: '登录中...',
      hideInMenu: true
    },
    component: () => import('@/views/loading-login-sso/loading-login-sso.vue')
  },
  {
    path: '/',
    name: 'home',
    component: Main,
    meta: {
      title: '首页',
      icon: 'icon-shouye'
    },
    redirect: '/home-page',
    children: [
      {
        path: '/home-page',
        name: 'home-page',
        meta: {
          title: '首页',
          code: ''
        },
        component: resolve => (require(['@/views/home/index.js'], resolve))
      },
      {
        path: '/developer-doc',
        name: 'developer-doc',
        meta: {
          title: '开发者文档',
          code: ''
        },
        component: resolve => (require(['@/views/developer-doc/index.js'], resolve))
      },
      {
        path: '/debug-doc',
        name: 'debug-doc',
        meta: {
          title: '在线调试',
          code: ''
        },
        component: resolve => (require(['@/views/debug-doc/index.js'], resolve))
      },
      {
        path: '/query-code',
        name: 'query-code',
        meta: {
          title: '错误码查询',
          code: ''
        },
        component: resolve => (require(['@/views/query-code/index.js'], resolve))
      }
    ]
  },
]
let newRoutes = [...router]
window.allRouters = newRoutes

export default newRoutes
