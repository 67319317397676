import axios from '@/libs/api.request'

export const errorCodeInfo = ({ errorCode }) => {
  return axios.request({
    url: `/api/jyq-base/opeanapi/app/errorCodeInfo?errorCode=${errorCode}`,
    method: 'post'
  })
}

export const getOtherInfo = ({ key }) => {
  return axios.request({
    url: `/api/auth/getOtherInfo?key=${key}`,
    method: 'post'
  })
}
