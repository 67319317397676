<template>
  <layout class="main">
    <Affix :offset-top="0">
      <Header @scroll="scroll" ref="headerDom" @cartRecommend="cartRecommend($event)"></Header>
    </Affix>
    <div class="main-bady" ref="outerDom" >
      <router-view/>
      <Footer @scroll="topScroll" @menuClick="menuClick($event)"></Footer>
    </div>
    <RecommendModal :modalType="modelShow" :goodList="goodList" @closeClick="cartRecommend($event)"></RecommendModal>
  </layout>
</template>

<script>
import { Base64 } from 'js-base64'
export default {
  components: {
    Header: resolve => (require(['@/components/main/components/header'], resolve)),
    Footer: resolve => (require(['@/components/main/components/footer'], resolve)),
    RecommendModal: resolve => (require(['@/views/components/confirm-modal/recommend-modal'], resolve))
  },
  data () {
    return {
      modelShow: false,
      goodList: []
    }
  },
  computed: {
    topScroll () {
      return this.$store.state.topScroll
    },
    relyShow () {
      return this.$store.state.relyShow
    },
    moduleLoad () {
      return this.$store.state.moduleLoad
    }
  },
  watch: {
    topScroll: {
      deep:true,//true为进行深度监听,false为不进行深度监听
      handler (val) {
        if (val) {
          window.scrollTo({ top: '0', behavior: 'smooth' })
        }
        this.$store.commit('set_topScroll', false)
      }
    },
    relyShow: {
      handler (val) {
        if (val) {
          this.cartRecommend({ show: true, goodList: sessionStorage.getItem('relyList') })
        }
        this.$store.commit('set_relyShow', false)
        this.$store.commit('del_relyShow')
      }
    },
    moduleLoad: {
      handler (val) {
        if (val) {
          if (this.$route.name != 'product-application') {
            this.mixCommon.catrAmount(this.$route.name, [this.$route.name])
          }
        }
        this.$store.commit('set_moduleLoad', false)
      }
    },
  },
  mounted () {
    // window.addEventListener("scroll", this.getScrollTop);
    window.addEventListener("beforeunload", (e) => {
      window.scroll(0, 0);
    });
  },
  methods: {
    cartRecommend (e) {
      let arr = []
      if (e.show) {
        if (e.goodList) {
          arr = JSON.parse(Base64.decode(e.goodList))
          this.goodList = arr
          console.log(arr)
        } else {
          this.goodList = []
        }
      }
      this.modelShow = e.show
    },
    menuClick(e) {
      if (e.type == 1) {
        this.$refs.headerDom.navClick(JSON.parse(e.item))
      } else if (e.type == 2) {
        this.$refs.headerDom.navClick(JSON.parse(e.item))
        this.$refs.headerDom.navItemClick(JSON.parse(e.item1))
      } else if (e.type == 3) {
        let url = `https://www.chsign.cn/`
        window.open(url, '_blank');
      }
    },
    scroll () {
      let scrollElem = this.$refs.outerDom
      // scrollElem.scrollTo({ top: 0, behavior: 'smooth' })
      // window.scrollTo({ top: '-14.4rem', behavior: 'smooth' })
      // scrollElem.scrollTo(0, 0 )
      window.scrollTo('-10.3rem', 0)
    }
  }
};
</script>
<style lang="less" scoped>
.main {
  position: relative;
  // .main-bady {
  //   height: ~'calc(100vh - 14.4rem)';
  //   overflow-y: auto;
  // }
  /deep/.ivu-affix {
    width: 100% !important;
  }
  .main-bady {
    position: relative;
    z-index: 0;
  }
}
</style>